import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Col,
  Row,
} from "react-bootstrap";
import { SpinningBlueCircleLoader } from "../../../components/common/loaders";
import ArcIcon from "../../../assets/images/icon/arc.svg";
import { useGetApiClient } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import AddNewArc from "./addNewArc";

const ArcList = ({ showAddNewArc, setShowAddNewArc, arcDetails, setArcDetails, changesModal, setChangesModal }) => {

  const [searchInput, setSearchInput] = useState("");
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [arcList, setArcList] = useState([]);
  const [loadArcDetail, setLoadArcDetail] = useState(false);

  const handleClickArc = async (item) => {
    setLoadArcDetail(true)
    try {
      const { data } = await useGetApiClient.get(`/admin/configuration/arc/get/${item.id}`);
      if (data?.error) throw new Error(data?.error);
      setArcDetails(data);
      setShowAddNewArc(true);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadArcDetail(false);
    }
  }

  useEffect(() => {
    const getArcList = async () => {
      setLoading(true);
      try {
        const { data } = await useGetApiClient.get(`/admin/configuration/arc/list?search=${searchInput}`);
        if (data?.error) throw new Error(data?.error);
        setArcList(data)
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    }

    !showAddNewArc && getArcList();
  }, [search, showAddNewArc]);

  return (
    <React.Fragment>
      {!showAddNewArc
        ? <>
          <Card className="config-tasks-list">
            <div className="config-tasks-list_header">
              <div className="d-flex flex-row justify-content-between w-100">
                <h5 className="text-primary">ARC</h5>
                <span className="table-add float-end mb-3 me-2">
                  <Button
                    className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                    disabled={loading}
                    onClick={() => setShowAddNewArc(true)}
                  >
                    <i className="material-symbols-outlined me-1 md-18">add</i>
                    Create ARC
                  </Button>
                </span>
              </div>
              <div className="d-flex flex-row align-items-center gap-2">
                <div className="" style={{ marginRight: '1rem' }}>ARC Templates</div>
                <input
                  type="text"
                  placeholder="-"
                  className=" w-25 border border-light rounded-3 px-2 py-1"
                  onChange={e => setSearchInput(e.target.value ?? "")}
                />
                <button
                  onClick={() => setSearch(!search)}
                  className="bg-primary rounded-3 text-white border-0 d-flex align-items-center py-1"
                >
                  <i className="material-symbols-outlined">search</i>
                </button>
              </div>
            </div>
          </Card>
          {(loading || loadArcDetail)
            ? <SpinningBlueCircleLoader />
            : <>
              <Row>
                {(arcList.arcs ?? []).map((item, index) => (
                  <Col xs lg="3" key={index} onClick={() => handleClickArc(item)} style={{ cursor: "pointer" }}>
                    <Card>
                      <div className="d-flex flex-row align-items-center gap-3 p-4">
                        <img src={ArcIcon} style={{ filter: index % 3 === 0 ? "unset" : index % 3 === 1 ? "brightness(0) saturate(100%) invert(65%) sepia(39%) saturate(1152%) hue-rotate(214deg) brightness(99%) contrast(104%)" : "brightness(0) saturate(100%) invert(79%) sepia(14%) saturate(1790%) hue-rotate(334deg) brightness(103%) contrast(100%)" }} />
                        <div className="me-1" style={{ color: index % 3 === 0 ? "#F7685B" : index % 3 === 1 ? "#D592FF" : "#FFBA68" }}>{item.name}</div>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          }
        </>
        : <AddNewArc arcDetails={arcDetails} setArcDetails={setArcDetails} arcList={arcList} changesModal={changesModal} setChangesModal={setChangesModal} setShowAddNewArc={setShowAddNewArc}  />
      }
    </React.Fragment>
  );
}

export default ArcList;