import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle, Row } from "react-bootstrap";
import { useGetApiClient, useUploadPictureApi } from "../../../../api/useApiClient";
import { toast } from "react-toastify";
import { CustomSelect } from "../../../../components/common/CustomSelect";
import defaultImage from "../../../../assets/images/user/default_image.png";
import { SpinningBlueCircleLoader, SpinningWhiteCircleLoader } from "../../../../components/common/loaders";
import ConfirmModal from "../../../../components/common/ConfirmModal";
import CameraModal from "./cameraModal";

const ViolationModal = ({
  open = false,
  rowData,
  onHide,
  openModal,
  setOpenModal
}) => {

  const fileInputRefs = useRef([]);
  const [violationOptions, setViolationOptions] = useState([]);
  const [violationLoading, setViolationLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState("");
  const [loading, setLoading] = useState("");
  const [cameraModal, setCameraModal] = useState({ open: false, data: "" })
  const [form, setForm] = useState({
    type: "",
    description: "",
    date: new Date(),
    time: "",
    documents: [{ name: "", file: defaultImage }],
  })

  const handleFormChange = (e, name) => {

    if (name === "type") {
      setForm({ ...form, [name]: e })
    }

    else {
      setForm({ ...form, [name]: e.target.value })
    }
  }

  const handleCapture = async (e) => {
    try {
      const index = cameraModal.data;
      const byteString = atob(e.split(",")[1]); // Decode Base64
      const mimeString = e.split(",")[0].split(":")[1].split(";")[0]; // Extract MIME type
      const buffer = new Uint8Array(byteString.length);

      for (let i = 0; i < byteString.length; i++) {
        buffer[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([buffer], { type: mimeString });
      const binary = new File([blob], "captured-image.jpg", { type: mimeString });

      setCameraModal({ open: false, data: "" })
      setImageLoading(index);
      const formData = new FormData();
      formData.append('file', binary);


      const { data: uploadedPicture } = await useUploadPictureApi.post('/admin/homeowner/violation/upload/document', formData);
      if (uploadedPicture?.error) throw new Error(uploadedPicture?.error);
      setForm({
        ...form, documents: form.documents.map((item, i) => {
          if (i === index) {
            return { name: "captured-image.jpg", file: uploadedPicture?.directoryPath }
            // return { name: e.target.files[0]?.name, file: defaultImage }
          } else return item
        })
      })
      setImageLoading("");
      setCameraModal({ open: false, data: "" })
    } catch (error) {
      toast.error(error?.message);
      setImageLoading("");
    }
  }

  const handleAttachPicture = async (e, index) => {

    setImageLoading(index);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    try {
      const { data: uploadedPicture } = await useUploadPictureApi.post('/admin/homeowner/violation/upload/document', formData);
      if (uploadedPicture?.error) throw new Error(uploadedPicture?.error);
      setForm({
        ...form, documents: form.documents.map((item, i) => {
          if (i === index) {
            return { name: e.target.files[0]?.name, file: uploadedPicture?.directoryPath }
            // return { name: e.target.files[0]?.name, file: defaultImage }
          } else return item
        })
      })
      setImageLoading("");
      const inputfile = document.getElementsByName(`picture[${index}]`)?.[0];
      inputfile.value = "";
    } catch (error) {
      toast.error(error?.message);
      setImageLoading("");
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const params = {
        violatorId: rowData?.id,
        violationId: form.type.id,
        description: form.description,
        dateSeen: `${form.date} ${form.time}:00`,
        documents: form.documents
      }

      const { data: savedViolation } = await useGetApiClient.post('/admin/homeowner/violation/save',
        params
      );
      if (savedViolation?.error) throw new Error(savedViolation?.error);
      setOpenModal({ ...openModal, save: true })
      setLoading(false);
      onHide();
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
      setLoading(false);
    }

  }

  useEffect(() => {
    const getList = async () => {
      setViolationLoading(true);
      try {
        const { data } = await useGetApiClient.get(`/admin/configuration/violation/list?search=`);
        if (data?.error) throw new Error(data?.error);
        setViolationOptions(data?.violations || [])
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setViolationLoading(false);
      }
    }

    getList();
  }, []);

  return (
    <>
      <Modal
        show={open}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <ModalBody>
            <button className="btn btn-primary position-absolute d-flex align-items-center" style={{ borderRadius: "30px 0px 0px 30px", left: "-41px", padding: "7px" }} onClick={onHide}>
              <i className="material-symbols-outlined">close</i>
            </button>
            <h5 className="text-primary mb-5">Violation Form</h5>
            <Row className="form-group align-items-center">
              <label className="control-label col-sm-3 align-self-center mb-0" htmlFor="type">Violation Type</label>
              <div className="col-sm-9">
                <CustomSelect
                  value={form.type}
                  onChange={(e) => handleFormChange(e, "type")}
                  options={violationOptions || []}
                  optLabelKey="name"
                  optValueKey="id"
                  placeholder="-"
                  isLoading={violationLoading}
                  required
                />
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-3 mb-0" htmlFor="description">Detailed Description</label>
              <div className="col-sm-9">
                <textarea
                  type="text"
                  value={form.description}
                  onChange={(e) => handleFormChange(e, "description")}
                  className="form-control"
                  id="description"
                  name="description"
                  placeholder="-"
                  required
                />
              </div>
            </Row>
            <Row className="form-group">
              <label className="control-label col-sm-3 mb-0" htmlFor="date">Date Seen</label>
              <div className="col-sm-4">
                <input
                  type="date"
                  value={form.date}
                  className="form-control"
                  id="date"
                  name="date"
                  placeholder="-"
                  onChange={e => handleFormChange(e, "date")}
                  required
                />
              </div>
              <div className="col-sm-4">
                <input
                  type="time"
                  value={form.time}
                  className="form-control"
                  id="time"
                  name="time"
                  placeholder="-"
                  onChange={e => handleFormChange(e, "time")}
                  required
                />
              </div>
            </Row>
            <Row>
              {form.documents.map((document, index) => (
                <div key={index} className="d-flex flex-column align-items-center justify-content-end col-sm-3 mt-3">
                  <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "100px" }}>
                    {imageLoading !== index ? (
                      <>
                        <img
                          src={form.documents[index]?.file}
                          // className="img-fluid"
                          alt="user"
                          loading="lazy"
                          style={{ width: "100%", height: "100%", objectFit: "contain" }}
                        />
                        <input
                          type="text"
                          value={form.documents[index]?.name}
                          style={{
                            position: "relative",
                            opacity: 0,
                            pointerEvents: 'none',
                            height: 0,
                            width: 0,
                            bottom: "50px"
                          }}
                          required
                        />
                      </>
                    ) : <SpinningBlueCircleLoader />}
                  </div>
                  <div className="d-flex flex-row gap-2 mt-4">
                    <div className="d-flex align-items-center">
                      <label htmlFor="picture" className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <i className="material-symbols-outlined" onClick={() => fileInputRefs.current[index].click()}>attach_file</i>
                      </label>
                      <input
                        ref={(el) => (fileInputRefs.current[index] = el)}
                        name={`picture[${index}]`}
                        type="file"
                        accept="image/*"
                        style={{
                          opacity: 0,
                          pointerEvents: 'none',
                          height: 0,
                          width: 0,
                        }}
                        onChange={(e) => handleAttachPicture(e, index)}
                      />
                    </div>
                    {form.documents.length > 1 &&
                      <button
                        type="button"
                        className="border-0 bg-transparent d-flex align-items-center text-danger"
                        onClick={() => setForm({ ...form, documents: form.documents.filter((_, i) => i !== index) })}
                      >
                        <i className="material-symbols-outlined">delete</i>
                      </button>
                    }
                    <button
                      type="button"
                      className="border-0 bg-transparent d-flex align-items-center text-primary"
                      onClick={() => setCameraModal({ open: true, data: index })}
                    >
                      <i className="material-symbols-outlined">photo_camera</i>
                    </button>
                  </div>
                </div>
              ))}
              <div className="d-flex col-sm-3 mt-3 align-items-center justify-content-center" style={{ height: "100px" }}>
                <i className="material-symbols-outlined filled text-primary w-auto cursor-pointer" style={{ fontSize: "27px" }} onClick={() => setForm({ ...form, documents: [...form.documents, { name: "", file: defaultImage }] })}>add_box</i>
              </div>
            </Row>
          </ModalBody>
          <ModalFooter style={{ border: "unset" }}>
            <button type="submit" className="btn btn-primary w-100">
              {!loading ? 'Submit' : <SpinningWhiteCircleLoader />}
            </button>
          </ModalFooter>
        </Form>
      </Modal>
      {cameraModal.open &&
        <CameraModal
          open={cameraModal.open}
          onChange={handleCapture}
          onHide={() => setCameraModal({ open: false, data: "" })}
        />
      }
    </>
  );
};

export default ViolationModal;