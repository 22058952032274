import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Modal, ModalHeader, ModalTitle, Row } from "react-bootstrap";
import { SpinningBlueCircleLoader, SpinningDynamicCircleLoader, SpinningWhiteCircleLoader } from "../../../components/common/loaders";
import { useGetApiClient, useUploadPictureApi } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import { CustomSelect } from "../../../components/common/CustomSelect";
import ConfirmModal from "../../../components/common/ConfirmModal";
import defaultUser from "../../../assets/images/user/default_user-gray.png";
import { prodApiUrl, stageApiUrl } from "../../../utilities/constants";

const AddNewVoting = ({ details, setDetails, list, changesModal, setChangesModal, setShowAddNew }) => {

  const candidates = list?.homeowners?.map(item => ({ ...item, name: `${item.ownerFirstName} ${item.ownerLastName}` }))
  const postingEvery = ["M", "T", "W", "TH", "F", "S", "SU"];
  const [loading, setLoading] = useState(false);
  const [pictureLoading, setPictureLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [flowModal, setFlowModal] = useState({ open: false, index: null });
  const [step, setStep] = useState(1);
  const [openModal, setOpenModal] = useState({ save: false, delete: false, confirmDelete: false });
  const [loadDelete, setLoadDelete] = useState(false);
  const [form, setForm] = useState({
    name: "",
    description: "",
    startDate: new Date(),
    endDate: new Date(),
    startTime: "",
    endTime: "",
    voters: "OWNER",
    posting: "DAILY",
    postingDay: [],
    postingEmailId: "",
    startEmailId: "",
    endEmailId: "",
    notifyStartDay: "",
    notifyStartEmailId: "",
    notifyEndDay: "",
    notifyEndEmailId: "",
    picture: defaultUser,
    candidates: []
  })

  const handleFormChange = (e, name, id) => {

    if (!changesModal.fields.find(f => f === name)) setChangesModal({ ...changesModal, fields: [...changesModal.fields, name] })

    if (name === "owners") {
      setForm({
        ...form,
        voters: "OWNER"
      })
    }
    else if (name === "co-owners") {
      setForm({
        ...form,
        voters: "CO-OWNER"
      })
    }
    else if (name === "candidates") {
      if (e.target.checked) {
        setForm({ ...form, candidates: [...form.candidates, id] })
      } else {
        setForm({ ...form, candidates: form.candidates.filter(item => item !== id) })
      }
    }
    else if (name === "posting_daily") {
      setForm({
        ...form,
        posting: "DAILY",
        postingDay: [],
      })
    }
    else if (name === "posting_every") {
      setForm({
        ...form,
        posting: "EVERY"
      })
    }
    else if (name === "posting_day") {
      if (e.target.checked) {
        setForm({ ...form, postingDay: [...form.postingDay, id] })
      } else {
        setForm({ ...form, postingDay: form.postingDay.filter(item => item !== id) })
      }
    }
    else if (name === "postingEmailId" || name === "startEmailId" || name === "endEmailId" || name === "notifyStartEmailId" || name === "notifyEndEmailId") {
      setForm({ ...form, [name]: e })
    }
    else {
      setForm({ ...form, [name]: e.target.value })
    }
  }

  const handleAttachPicture = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    try {
      setPictureLoading(true);
      const { data: uploadedPicture } = await useUploadPictureApi.post('/admin/configuration/voting/upload/picture', formData);
      if (uploadedPicture?.error) throw new Error(uploadedPicture?.error);
      setForm({ ...form, picture: uploadedPicture?.directoryPath });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      const inputfile = document.getElementsByName("picture")?.[0];
      inputfile.value = "";
      setPictureLoading(false);
    }
  }

  const handleDelete = async () => {
    setLoadDelete(true)
    try {
      const { data } = await useGetApiClient.delete(`/admin/configuration/voting/delete/${details?.id}`);
      if (data?.error) throw new Error(data?.error);
      // toast.success(data?.message);
      setOpenModal({ ...openModal, confirmDelete: false, delete: true })
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadDelete(false);
    }
  }

  const handleSubmit = async ({ event, type }) => {
    event.preventDefault();

    if (form.candidates.length === 0) {
      toast.error("Select at least 1 candidate.");
      return;
    }

    if (form.posting === "EVERY" && form.postingDay.length === 0) {
      toast.error("Select at least 1 posting day.");
      return;
    }

    if (form.picture === defaultUser) {
      toast.error("Picture is required.");
      return;
    }

    try {
      setLoading(true);
      const params = {
        name: form.name,
        description: form.description,
        startDate: `${form.startDate} ${form.startTime}:00`,
        endDate: `${form.endDate} ${form.endTime}:00`,
        voters: form.voters,
        posting: form.posting,
        postingDay: form.postingDay,
        postingEmailId: form.postingEmailId.id,
        startEmailId: form.startEmailId.id,
        endEmailId: form.endEmailId.id,
        notifyStartDay: form.notifyStartDay,
        notifyStartEmailId: form.notifyStartEmailId.id,
        notifyEndDay: form.notifyEndDay,
        notifyEndEmailId: form.notifyEndEmailId.id,
        picture: form.picture,
        candidates: form.candidates,
      };

      if (type === "add") {
        const { data } = await useGetApiClient.post('/admin/configuration/voting/save',
          params
        );

        if (data?.error) throw new Error(data?.error);
        setOpenModal({ ...openModal, save: true })

      } else {
        const { data } = await useGetApiClient.put(`admin/configuration/voting/update/${details?.id}`,
          params
        );
        if (data?.error) throw new Error(data?.error);
        setOpenModal({ ...openModal, save: true })

      }
      setLoading(false);
      setChangesModal({ open: false, fields: [], targetTab: null })
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (details) {
      setForm({
        ...form,
        name: details?.name,
        description: details?.description,
        picture: details.picture?.includes(stageApiUrl)
          ? details.picture?.replaceAll(stageApiUrl, "")
          : details.picture?.includes(prodApiUrl)
            ? details.picture?.replaceAll(prodApiUrl, "")
            : defaultUser,
        startDate: details.startDate.split(" ")[0],
        endDate: details.endDate.split(" ")[0],
        startTime: details.startDate.split(" ")[1].slice(0, 5),
        endTime: details.endDate.split(" ")[1].slice(0, 5),
        voters: details.voters,
        posting: details.posting,
        postingDay: details.postingDay,
        postingEmailId: details.postingEmail,
        startEmailId: details.startEmail,
        endEmailId: details.endEmail,
        notifyStartDay: details.notifyStartDay,
        notifyStartEmailId: details.notifyStartEmail,
        notifyEndDay: details.notifyEndDay,
        notifyEndEmailId: details.notifyEndEmail,
        candidates: details.candidates.map(item => item.homeownerId),
      })
    }
  }, [details, list])

  return (
    <>
      <Form
        onSubmit={(event) => {
          handleSubmit({
            event,
            type: details ? "update" : "add"
          })
        }}
      >
        <Card className="config-tasks-list">
          <div className="config-tasks-list_header">
            <div className="d-flex flex-row justify-content-between w-100">
              <h5 className="text-primary">Voting</h5>
              {details?.id &&
                <Button
                  className="btn btn-sm btn-danger-subtle d-flex align-items-center rounded-3"
                  style={{ border: "unset" }}
                  onClick={loadDelete ? () => { } : () => setOpenModal({ ...openModal, confirmDelete: true })}
                >
                  {loadDelete
                    ? <SpinningDynamicCircleLoader color="var(--bs-danger)" />
                    : <i className="material-symbols-outlined md-18">delete</i>
                  }
                </Button>
              }
            </div>
          </div>
          <Card.Body className="pt-0">
            <Row className="form-group align-items-center">
              <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Election Name</label>
              <div className="col-sm-4">
                <input type="text" value={form.name} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
              </div>
            </Row>

            <Row className="form-group align-items-center">
              <label className="control-label col-sm-2 align-self-start mt-1 mb-2" htmlFor="description">Description</label>
              <div className="col-sm-4">
                <textarea type="text" value={form.description} onChange={(e) => handleFormChange(e, "description")} className="form-control" style={{ minHeight: "100px" }} id="description" name="description" placeholder="-" required />
              </div>
            </Row>

            <Row className="form-group align-self-center">
              <label className="control-label col-sm-2 align-self-start mt-1 mb-2" htmlFor="picture">Picture</label>
              <div className="col-sm-4 d-flex flex-column align-items-center justify-content-center">
                <div>
                  {!pictureLoading ? (
                    <img
                      src={form.picture}
                      className="img-fluid"
                      alt="user"
                      loading="lazy"
                    />
                  ) : <SpinningBlueCircleLoader />}
                </div>
                <div className="d-flex flex-row gap-3 mt-4">
                  <div className="d-flex align-items-center">
                    <label htmlFor="picture" className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                      <i className="material-symbols-outlined">attach_file</i>
                    </label>
                    <input
                      id="picture"
                      name="picture"
                      type="file"
                      accept="image/*"
                      className="d-none"
                      onChange={(e) => handleAttachPicture(e)}
                    />
                  </div>
                  <button
                    type="button"
                    className="border-0 bg-transparent d-flex align-items-center text-danger"
                    onClick={() => setForm({ ...form, picture: defaultUser })}
                  >
                    <i className="material-symbols-outlined">delete</i>
                  </button>
                </div>
              </div>
            </Row>

            <Row className="form-group align-items-center">
              <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Start Date</label>
              <div className="col-sm-4">
                <input
                  type="date"
                  value={form.startDate}
                  className="form-control"
                  id="startDate"
                  name="startDate"
                  placeholder="-"
                  onChange={e => handleFormChange(e, "startDate")}
                  required
                />
              </div>
              <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">End Date</label>
              <div className="col-sm-4">
                <input
                  type="date"
                  value={form.endDate}
                  className="form-control"
                  id="endDate"
                  name="endDate"
                  placeholder="-"
                  onChange={e => handleFormChange(e, "endDate")}
                  required
                />
              </div>
            </Row>

            <Row className="form-group align-items-center">
              <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Start Time</label>
              <div className="col-sm-4">
                <input
                  type="time"
                  value={form.startTime}
                  className="form-control"
                  id="startTime"
                  name="startTime"
                  placeholder="-"
                  onChange={e => handleFormChange(e, "startTime")}
                  required
                />
              </div>
              <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">End Time</label>
              <div className="col-sm-4">
                <input
                  type="time"
                  value={form.endTime}
                  className="form-control"
                  id="endTime"
                  name="endTime"
                  placeholder="-"
                  onChange={e => handleFormChange(e, "endTime")}
                  required
                />
              </div>
            </Row>

            <Row className="form-group align-items-start">
              <div className="col-sm-6 mt-3">
                <h6 className="text-primary mb-3">Eligible Voters</h6>
                <Form.Check
                  type="checkbox"
                  label="Owners"
                  name="owners"
                  checked={form.voters === "OWNER"}
                  onChange={(e) => handleFormChange(e, "owners")}
                />
                <Form.Check
                  type="checkbox"
                  label="Co Owners"
                  name="co-owners"
                  checked={form.voters === "CO-OWNER"}
                  onChange={(e) => handleFormChange(e, "co-owners")}
                  className="mt-2"
                />
              </div>
              <div className="col-sm-6 mt-3">
                <h6 className="text-primary mb-3">Candidates</h6>
                <div
                  className="form-control"
                  style={{ maxHeight: 150, overflow: "auto" }}
                >
                  {candidates?.map((item, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={item.name}
                      name={`candidate_${item.id}`}
                      checked={form.candidates.includes(item.id)}
                      onChange={(e) => handleFormChange(e, "candidates", item.id)}
                    />
                  ))}
                </div>
              </div>
            </Row>

            <h6 className="text-primary mb-3 mt-5">Posting</h6>
            <div className="col-sm-12">
              <Form.Check className="d-block mb-0">
                <Form.Check.Input
                  type="radio"
                  name="posting_daily"
                  id="posting_daily"
                  checked={form.posting === "DAILY"}
                  onChange={(e) => handleFormChange(e, "posting_daily")}
                />{" "}
                <Form.Check.Label>
                  Repost daily
                </Form.Check.Label>
              </Form.Check>
            </div>
            <div className="col-sm-12 mt-2">
              <Form.Check className="d-block mb-0">
                <Form.Check.Input
                  type="radio"
                  name="posting_every"
                  id="posting_every"
                  checked={form.posting === "EVERY"}
                  onChange={(e) => handleFormChange(e, "posting_every")}
                />{" "}
                <Form.Check.Label>
                  Repost every
                </Form.Check.Label>
              </Form.Check>
            </div>
            <div className="col-sm-12 d-flex align-items-center gap-4 mt-2">
              {postingEvery.map((item, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={item}
                  name={`postingDay_${item}`}
                  checked={form.postingDay.includes(item)}
                  onChange={(e) => handleFormChange(e, "posting_day", item)}
                  disabled={form.posting === "DAILY"}
                />
              ))}
            </div>
            <Row className="form-group align-items-center">
              <label className="control-label col-sm-2 align-self-center mb-2 mt-3" htmlFor="name">Email</label>
              <div className="col-sm-4 mt-3">
                <CustomSelect
                  value={form.postingEmailId}
                  onChange={(e) => handleFormChange(e, "postingEmailId")}
                  options={list?.emailTemplates || []}
                  optLabelKey="notificationId"
                  optValueKey="id"
                  placeholder="Email template"
                  required
                />
              </div>
            </Row>

            <Row className="form-group align-items-center">
              <label className="control-label col-sm-2 align-self-center mb-2 mt-3" htmlFor="name">Email start election</label>
              <div className="col-sm-4 mt-3">
                <CustomSelect
                  value={form.startEmailId}
                  onChange={(e) => handleFormChange(e, "startEmailId")}
                  options={list?.emailTemplates || []}
                  optLabelKey="notificationId"
                  optValueKey="id"
                  placeholder="Email template"
                  required
                />
              </div>
              <label className="control-label col-sm-2 align-self-center mb-2 mt-3" htmlFor="name">Days notify start of election</label>
              <div className="col-sm-1 mt-3">
                <input type="number" value={form.notifyStartDay} className="form-control" id="notifyStartDay" name="notifyStartDay" placeholder="-" onChange={(e) => handleFormChange(e, "notifyStartDay")} required />
              </div>
              <div className="col-sm-3 mt-3">
                <CustomSelect
                  value={form.notifyStartEmailId}
                  onChange={(e) => handleFormChange(e, "notifyStartEmailId")}
                  options={list?.emailTemplates || []}
                  optLabelKey="notificationId"
                  optValueKey="id"
                  placeholder="Email template"
                  required
                />
              </div>
              <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Email end election</label>
              <div className="col-sm-4">
                <CustomSelect
                  value={form.endEmailId}
                  onChange={(e) => handleFormChange(e, "endEmailId")}
                  options={list?.emailTemplates || []}
                  optLabelKey="notificationId"
                  optValueKey="id"
                  placeholder="Email template"
                  required
                />
              </div>
              <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Days notify end of election</label>
              <div className="col-sm-1">
                <input type="number" value={form.notifyEndDay} className="form-control" id="notifyEndDay" name="notifyEndDay" placeholder="-" onChange={(e) => handleFormChange(e, "notifyEndDay")} required />
              </div>
              <div className="col-sm-3">
                <CustomSelect
                  value={form.notifyEndEmailId}
                  onChange={(e) => handleFormChange(e, "notifyEndEmailId")}
                  options={list?.emailTemplates || []}
                  optLabelKey="notificationId"
                  optValueKey="id"
                  placeholder="Email template"
                  required
                />
              </div>
            </Row>
          </Card.Body>
        </Card>

        <Form.Check className="form-group d-flex align-items-center justify-content-center gap-2">
          <button type="submit" className="btn btn-primary" style={{ width: "30%" }}>
            {!loading ? 'Save' : <SpinningWhiteCircleLoader />}
          </button>
        </Form.Check>

      </Form >

      <ConfirmModal
        open={openModal.save}
        onHide={() => setOpenModal({ ...openModal, save: false })}
        titleIcon={<i className="material-symbols-outlined text-success" style={{ fontSize: "130px" }}>check_circle</i>}
        titleText={<h5 className="text-success">Save</h5>}
        confirmText="Successfully saved."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-success w-100" onClick={() => setOpenModal({ ...openModal, save: false })}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.delete}
        onHide={() => setOpenModal({ ...openModal, delete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Successfully deleted."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-danger w-100" onClick={() => {
            setOpenModal({ ...openModal, delete: false });
            setShowAddNew(false);
            setDetails(null);
          }}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.confirmDelete}
        onHide={() => setOpenModal({ ...openModal, confirmDelete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Are you sure you want to delete?"
        isTwoActions={true}
        confirmButton={
          <button className="btn btn-danger w-50" disabled={loadDelete} onClick={handleDelete}>
            {loadDelete ? <SpinningWhiteCircleLoader /> : "Yes"}
          </button>
        }
        cancelButton={
          <button className="btn btn-primary w-50" onClick={() => setOpenModal({ ...openModal, confirmDelete: false })}>
            No
          </button>
        }
      />
    </>
  );
}

export default AddNewVoting;