import { useRef, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import Webcam from "react-webcam";

const CameraModal = ({
  open = false,
  onChange,
  onHide,
}) => {
  const webcamRef = useRef(null);

  const [facingMode, setFacingMode] = useState("enviroment");

  return (
    <Modal
      show={open}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width="100%"
          videoConstraints={{
            width: 1280,
            height: 720,
            facingMode: facingMode
          }}
        />
      </ModalBody>
      <ModalFooter style={{ border: "unset" }}>
        <div className="d-flex flex-row gap-3 mt-4 w-100 justify-content-center">
          <Button
            type="button"
            className="border-0 bg-black d-flex align-items-center text-white"
            onClick={() => setFacingMode(facingMode === "environment" ? "user" : "environment")}
          >
            <i className="material-symbols-outlined">flip_camera_android</i>
          </Button>
          <Button
            type="button"
            className="border-0 bg-primary d-flex align-items-center text-white"
            onClick={() => onChange(webcamRef.current.getScreenshot())}
          >
            <i className="material-symbols-outlined">photo_camera</i>
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default CameraModal;