import { create } from "zustand";
import { persist } from "zustand/middleware";

const useDashboardStore = create(persist(
    (set) => ({
        authUser: {},
        setAuthUser: (authUser) => set({authUser}),
        sidebarMenus: [],
        setSidebarMenus: (sidebarMenus) => set({sidebarMenus}),
        role: {},
        setRole: (role) => set({role}),
        activeTab: null,
        setActiveTab: (activeTab) => set({activeTab})
    })
))

export default useDashboardStore;