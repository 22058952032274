import axios from "axios";

const token = localStorage.getItem("token");
const redirectToken = new URLSearchParams(window.location.search).get("token");

export const useGetApiClient = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        'Authorization': `Bearer ${token || redirectToken}` 
    }
})

export const useUploadPictureApi = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
    },
})