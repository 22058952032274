import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useGetApiClient } from '../../api/useApiClient';

const useGetUserProfileApi = () => {
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        const getUserProfile = async () => {
            setLoading(true);
            try {
                const { data } = await useGetApiClient.get('/admin/user/me');
                setUserInfo(data);
            } catch (error) {
                toast.error(error?.response?.data?.message);
            } finally {
                setLoading(false);
            }
        };

        getUserProfile();
    }, []);

    return { loading, userInfo };
};

export default useGetUserProfileApi;