import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, Row } from "react-bootstrap";
import { SpinningBlueCircleLoader, SpinningWhiteCircleLoader } from "../../../../components/common/loaders";
import defaultUser from "../../../../assets/images/user/default_user-gray.png";
import { useGetApiClient, useUploadPictureApi } from "../../../../api/useApiClient";
import { toast } from "react-toastify";
import { CustomSelect } from "../../../../components/common/CustomSelect";
import ConfirmModal from "../../../../components/common/ConfirmModal";
import { prodApiUrl, stageApiUrl } from "../../../../utilities/constants";
import useDashboardStore from "../../../../zustand/useDashboardStore";
import { useNavigate } from "react-router-dom";

const AddNewUser = ({
  open = false,
  onHide,
  rolesList,
  userDetails,
  refetch,
}) => {

  const { authUser, setAuthUser } = useDashboardStore();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openModal, setOpenModal] = useState({ save: false, delete: false, confirmDelete: false, logout: false });
  const [initialRoles, setInitialRoles] = useState([]);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    username: "",
    contactNumber: "",
    email: "",
    roles: [],
    autoPassword: false,
    password: "",
    passwordVisible: "",
    confirmPassword: "",
    confirmPasswordVisible: "",
    picture: defaultUser,
    emergencyContact: {
      name: null,
      contactNumber: null,
      email: null,
      relation: null,
    }
  })

  const resetForm = () => {
    setForm({
      firstName: "",
      lastName: "",
      username: "",
      contactNumber: "",
      email: "",
      roles: [],
      autoPassword: false,
      password: "",
      passwordVisible: "",
      confirmPassword: "",
      confirmPasswordVisible: "",
      picture: defaultUser,
      emergencyContact: {
        name: null,
        contactNumber: null,
        email: null,
        relation: null,
      }
    })
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (form.password !== form.confirmPassword) {
      toast.error("Password and Re-type password did not match.");
      return;
    }

    try {
      setSubmitLoading(true);
      const params = {
        firstName: form.firstName,
        lastName: form.lastName,
        username: form.username,
        contactNumber: form.contactNumber,
        email: form.email,
        roles: form.roles.map(role => role.id),
        autoPassword: form.autoPassword,
        password: form.password,
        picture: form.picture === defaultUser ? null : form.picture,
        emergencyContact: form.emergencyContact
      }

      if (!userDetails) {
        const { data } = await useGetApiClient.post('/admin/user/save',
          params
        );

        if (data?.error) throw new Error(data?.error);
        setOpenModal({ ...openModal, save: true })
      } else {
        const { data } = await useGetApiClient.put(`admin/user/update/${userDetails?.id}`,
          params
        );
        if (data?.error) throw new Error(data?.error);
        setOpenModal({ ...openModal, save: true })
        if (userDetails?.id === authUser?.id) {
          setAuthUser({
            ...authUser,
            contactNumber: form.contactNumber,
            email: form.email,
            picture: form.picture === defaultUser ? null : form.picture,
            username: form.username,
          })
          localStorage.setItem("loggedInUser", form.username);
          localStorage.setItem("username", form.username);
        }
        const arr1 = initialRoles.map(item => item.id);
        const arr2 = form.roles.map(item => item.id);

        if (authUser?.id === 1 && userDetails?.id === authUser?.id && (arr1.length !== arr2.length || arr1.some((value, index) => value !== arr2[index]))) {
          localStorage.clear();
          setOpenModal({ ...openModal, logout: true })
        }
      }

      setSubmitLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
      setSubmitLoading(false);
    }
  }

  const handleFormOnChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setForm({ ...form, [name]: value });
  }

  const handleAttachPicture = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    try {
      setLoading(true);
      const { data: uploadedPicture } = await useUploadPictureApi.post('/admin/user/upload/picture', formData);
      if (uploadedPicture?.error) throw new Error(uploadedPicture?.error);
      setForm({ ...form, picture: uploadedPicture?.directoryPath });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      const inputfile = document.getElementsByName("picture")?.[0];
      inputfile.value = "";
      setLoading(false);
    }
  }

  useEffect(() => {
    const getUserConfig = async () => {
      setSubmitLoading(true);
      try {
        const { data } = await useGetApiClient.get(`/admin/user/get/${userDetails?.id}`);
        if (data?.error) throw new Error(data?.error);
        setInitialRoles(rolesList?.filter(obj => data.roles.includes(obj.id)));
        setForm({
          ...form,
          firstName: data.firstName,
          lastName: data.lastName,
          username: data.username,
          contactNumber: data.contactNumber,
          email: data.email,
          roles: rolesList?.filter(obj => data.roles.includes(obj.id)),
          picture: data.picture?.includes(stageApiUrl)
            ? data.picture?.replaceAll(stageApiUrl, "")
            : data.picture?.includes(prodApiUrl)
              ? data.picture?.replaceAll(prodApiUrl, "")
              : defaultUser
        })
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setSubmitLoading(false);
      }
    }

    userDetails?.id && getUserConfig();
  }, [userDetails?.id])

  return (
    <>
      <Modal
        show={open}
        onHide={() => {
          onHide();
          resetForm();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <button className="btn btn-primary position-absolute d-flex align-items-center" style={{ borderRadius: "30px 0px 0px 30px", left: "-41px", padding: "7px" }} onClick={() => {
              onHide();
              resetForm();
            }}>
              <i className="material-symbols-outlined">close</i>
            </button>
            <h5 className="text-primary mb-5">{userDetails?.id ? "Edit" : "Add"} User</h5>
            <Row>
              <div className="col-sm-8">
                <Row className="form-group">
                  <label className="control-label col-sm-4 align-self-center mb-3" htmlFor="firstName">First Name</label>
                  <div className="col-sm-7 mb-3">
                    <input type="text" value={form.firstName} className="form-control" id="firstName" name="firstName" placeholder="-" onChange={handleFormOnChange} required />
                  </div>
                  <div className="col-sm-1 mb-3" />

                  <label className="control-label col-sm-4 align-self-center mb-3" htmlFor="lastName">Last Name</label>
                  <div className="col-sm-7 mb-3">
                    <input type="text" value={form.lastName} className="form-control" id="lastName" name="lastName" placeholder="-" onChange={handleFormOnChange} required />
                  </div>
                  <div className="col-sm-1 mb-3" />

                  <label className="control-label col-sm-4 align-self-center mb-3" htmlFor="username">Username</label>
                  <div className="col-sm-7 mb-3">
                    <input type="text" value={form.username} className="form-control" id="username" name="username" placeholder="-" onChange={handleFormOnChange} required />
                  </div>
                  <div className="col-sm-1 mb-3" />

                  <label className="control-label col-sm-4 align-self-center mb-3" htmlFor="contactNumber">Contact Number</label>
                  <div className="col-sm-7 mb-3">
                    <input type="text" value={form.contactNumber} className="form-control" id="contactNumber" name="contactNumber" placeholder="-" onChange={handleFormOnChange} required />
                  </div>
                  <div className="col-sm-1 mb-3" />

                  <label className="control-label col-sm-4 align-self-center mb-3" htmlFor="email">Email</label>
                  <div className="col-sm-7 mb-3">
                    <input type="email" value={form.email} className="form-control" id="email" name="email" placeholder="-" onChange={handleFormOnChange} required />
                  </div>
                  <div className="col-sm-1 mb-3" />

                  <label className="control-label col-sm-4 align-self-center mb-3" htmlFor="roles">Role</label>
                  <div className="col-sm-7 mb-3">
                    <CustomSelect
                      value={form.roles}
                      onChange={(e) => setForm({ ...form, roles: e })}
                      options={rolesList || []}
                      optLabelKey="name"
                      optValueKey="id"
                      isMulti
                      required
                      isDisabled={userDetails?.id && authUser?.id !== 1}
                    />
                  </div>
                  <div className="col-sm-1 mb-3" />

                  <label className="control-label col-sm-4 align-self-center mb-3" htmlFor="password">Password</label>
                  <div className="col-sm-7 mb-3">
                    <input type={form.passwordVisible ? "text" : "password"} value={form.password} className="form-control" id="password" name="password" placeholder="-" onChange={handleFormOnChange} required={!userDetails?.id || form.confirmPassword} />
                  </div>
                  <div className="col-sm-1 mb-3 align-self-center">
                    <Button
                      className="btn btn-sm btn-primary-subtle d-flex align-items-center rounded-3"
                      style={{ border: "unset" }}
                      onClick={() => setForm({ ...form, passwordVisible: !form.passwordVisible })}
                    >
                      {form.passwordVisible
                        ? <i className="material-symbols-outlined md-18">visibility</i>
                        : <i className="material-symbols-outlined md-18">visibility_off</i>
                      }
                    </Button>
                  </div>

                  <label className="control-label col-sm-4 align-self-center mb-3" htmlFor="confirmPassword">Re-type Password</label>
                  <div className="col-sm-7 mb-3">
                    <input type={form.confirmPasswordVisible ? "text" : "password"} value={form.confirmPassword} className="form-control" id="confirmPassword" name="confirmPassword" placeholder="-" onChange={handleFormOnChange} required={!userDetails?.id || form.password} />
                  </div>
                  <div className="col-sm-1 mb-3 align-self-center">
                    <Button
                      className="btn btn-sm btn-primary-subtle d-flex align-items-center rounded-3"
                      style={{ border: "unset" }}
                      onClick={() => setForm({ ...form, confirmPasswordVisible: !form.confirmPasswordVisible })}
                    >
                      {form.confirmPasswordVisible
                        ? <i className="material-symbols-outlined md-18">visibility</i>
                        : <i className="material-symbols-outlined md-18">visibility_off</i>
                      }
                    </Button>
                  </div>
                </Row>
              </div>
              <div className="col-sm-4 align-self-center">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div>
                    {!loading ? (
                      <img
                        src={form.picture}
                        className="img-fluid"
                        alt="user"
                        loading="lazy"
                      />
                    ) : <SpinningBlueCircleLoader />}
                  </div>
                  <div className="d-flex flex-row gap-3 mt-4">
                    <div className="d-flex align-items-center">
                      <label htmlFor="picture" className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <i className="material-symbols-outlined">attach_file</i>
                      </label>
                      <input
                        id="picture"
                        name="picture"
                        type="file"
                        accept="image/*"
                        className="d-none"
                        onChange={(e) => handleAttachPicture(e)}
                      />
                    </div>
                    <button
                      type="button"
                      className="border-0 bg-transparent d-flex align-items-center text-danger"
                      onClick={() => setForm({ ...form, picture: defaultUser })}
                    >
                      <i className="material-symbols-outlined">delete</i>
                    </button>
                  </div>
                </div>
              </div>
            </Row>
          </ModalBody>
          <ModalFooter style={{ border: "unset" }}>
            <button className="btn btn-primary w-100" disabled={submitLoading}>
              {submitLoading ? <SpinningWhiteCircleLoader /> : "Submit"}
            </button>
          </ModalFooter>
        </form>
      </Modal>
      <ConfirmModal
        open={openModal.save}
        onHide={() => {
          setOpenModal({ ...openModal, save: false });
          onHide();
          resetForm();
          refetch();
        }}
        titleIcon={<i className="material-symbols-outlined text-success" style={{ fontSize: "130px" }}>check_circle</i>}
        titleText={<h5 className="text-success">Save</h5>}
        confirmText="Successfully saved."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-success w-100" onClick={() => {
            setOpenModal({ ...openModal, save: false });
            onHide();
            resetForm();
            refetch();
          }}>
            Okay
          </button>
        }
      />
      <ConfirmModal
        open={openModal.logout}
        onHide={() => {
          setOpenModal({ ...openModal, logout: false });
          navigate(`/auth/sign-in`);
        }}
        titleIcon={<i className="material-symbols-outlined text-primary" style={{ fontSize: "130px" }}>info</i>}
        titleText={<h5 className="text-primary">Logged out</h5>}
        confirmText="You will be logged out due to changes in your roles."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-primary w-100" onClick={() => {
            setOpenModal({ ...openModal, logout: false });
            navigate(`/auth/sign-in`);
          }}>
            Okay
          </button>
        }
      />
    </>
  );
};

export default AddNewUser;