import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetApiClient } from "../../api/useApiClient";

const useGetNotificationListApi = (search, searchInput, showAddNewNotification) => {
  const [loading, setLoading] = useState(false);
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    const getNotificationList = async () => {
      setLoading(true);
      try {
        const { data } = await useGetApiClient.get(`/admin/configuration/notification/list?search=${searchInput}`);
        if (data?.error) throw new Error(data?.error);
        setNotificationList(data)
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    }

    !showAddNewNotification && getNotificationList();
  }, [search, showAddNewNotification]);

  return { loading, notificationList }
};

export const useGetVariablesApi = () => {
  const [loading, setLoading] = useState(false);
  const [variablesList, setVariablesList] = useState([]);

  useEffect(() => {
    const getVariableList = async () => {
      setLoading(true);
      try {
        const { data } = await useGetApiClient.get(`/admin/configuration/notification/variables`);
        if (data?.error) throw new Error(data?.error);
        setVariablesList(data)
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    }

    getVariableList();
  }, []);

  return { loading, variablesList }
}

export default useGetNotificationListApi;