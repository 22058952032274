import React from "react";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";

const ConfirmModal = ({
  open = false,
  onHide,
  titleIcon = null,
  titleText = "",
  confirmText = "",
  isTwoActions = false,
  isOneAction = false,
  confirmButton = null,
  cancelButton = null,
}) => {
  return (
    <Modal
      show={open}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <button className="btn btn-primary position-absolute d-flex align-items-center" style={{ borderRadius: "30px 0px 0px 30px", left: "-41px", padding: "7px" }} onClick={onHide}>
          <i className="material-symbols-outlined">close</i>
        </button>
        {titleText}
        {titleIcon &&
          <div className="d-flex justify-content-center">
            {titleIcon}
          </div>
        }
        {confirmText && <p className="text-center mt-2">{confirmText}</p>}
      </ModalBody>
      <ModalFooter style={{ border: "unset" }}>
        <>
          {isTwoActions && (
            <div className="d-flex align-items-center justify-content-center gap-2 w-100">
              {confirmButton}
              {cancelButton}
            </div>
          )}
          {isOneAction && confirmButton}
        </>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;