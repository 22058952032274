// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.config-tasks-list .config-tasks-list_header {
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
}
.config-tasks-list .config-tasks-list_table .config-tasks-list_table--header {
  background-color: #DCF0FF;
  text-transform: none !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/screens/system-configuration/config-tasks-list.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;AAAR;AAKQ;EACI,yBAAA;EACA,+BAAA;AAHZ","sourcesContent":[".config-tasks-list {\n    .config-tasks-list_header {\n        padding: 1.875rem;\n        display: flex;\n        flex-direction: column;\n    }\n\n    .config-tasks-list_table {\n\n        .config-tasks-list_table--header {\n            background-color: #DCF0FF;\n            text-transform: none !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
