import React, { useEffect, useState } from 'react';
import { useGetApiClient } from '../../api/useApiClient';
import { toast } from 'react-toastify';

const useGetRolesApi = () => {
    const [loading, setLoading] = useState(false);
    const [rolesMenus, setRolesMenus] = useState(null);

    useEffect(() => {
        const getRolesMenus = async() => {
            setLoading(true);
            try {
                const { data } = await useGetApiClient.get('/admin/role/menus');
                if (data?.error) throw new Error(data?.error);
                setRolesMenus(data);
            } catch (error) {
                toast.error(error?.message);
            } finally {
                setLoading(false);
            }
        };

        getRolesMenus();
    }, []);

    return {loading, rolesMenus};
}

export default useGetRolesApi;