import React from "react";

//header
import Header from "../../components/partials/dashboard/HeaderStyle/header";

//sidebar
import RightSidebar from "../../components/partials/dashboard/SidebarStyle/rightsidebar";

//sidebar
import Sidebar from "../../components/partials/dashboard/SidebarStyle/sidebar";

//footer
import Footer from "../../components/partials/dashboard/FooterStyle/footer";

//settingoffCanvas
import SettingOffCanvas from "../../components/setting/SettingOffCanvas";
import { Outlet } from "react-router-dom";
import { useAuthContext } from "../../contexts/auth/AuthContext";
import SignIn from "../../views/dashboard/auth/sign-in";
import { Container } from "react-bootstrap";

const Default = () => {
  const { authUser } = useAuthContext()  ;

  if (!authUser) return <SignIn />;
  return (
    <>
      <Sidebar />
      {/* <HeaderOne/> */}
      <div className="main-content">
        <div className="position-relative">
        <Header />
        {/* <div id="content-page" className="content-inner"> */}
        {/* <DefultRouter/> */}
        <Container style={{ marginBottom: 60 }}>
          <Outlet />
        </Container>
        {/* </div> */}

        </div>
      </div>
      {/* <RightSidebar /> */}
      <Footer />
      <SettingOffCanvas />
    </>
  );
};

export default Default;
