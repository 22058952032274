import Select, { components } from "react-select";

export const CustomSelect = (
  {
    isClearable = false,
    isSearchable = true,
    isDisabled = false,
    isLoading = false,
    options = [],
    optLabelKey,
    optValueKey,
    placeholder,
    menuPlacement = "bottom",
    isMulti = false,
    onChange,
    value,
    required = false,
    ...restProps
  }
) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: "auto",
      border: state.isFocused
        ? "var(--bs-border-width) solid var(--bs-primary)"
        : "var(--bs-border-width) solid var(--bs-border-color)",
      width: "100%",
      fontSize: "0.875rem",
      fontWeight: "400",
      borderRadius: "var(--bs-border-radius)",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#E5E5E5",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: "0.575rem 0.75rem",
    }),
    input: (base, state) => ({
      ...base,
      margin: 0,
      padding: 0,
      color: "var(--bs-body-color)",
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      background: "transparent",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "var(--bs-body-color)",
      margin: 0,
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: 2,
    }),
  };

  return (
    <Select
      isClearable={isClearable}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      options={options}
      getOptionLabel={(option) => option[optLabelKey]}
      getOptionValue={(option) => option[optValueKey]}
      onChange={onChange}
      styles={customStyles}
      placeholder={placeholder}
      value={value}
      isLoading={isLoading}
      menuPlacement={menuPlacement}
      isMulti={isMulti}
      required={required}
      {...restProps}
    />
  )
}