import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { SpinningBlueCircleLoader } from "../../../components/common/loaders";
import { useGetApiClient } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import Pagination from "../../../components/common/pagination";
import ViolationStatus from "./violationStatus";

const ViolationList = ({ showAddNew, setShowAddNew, details, setDetails, changesModal, setChangesModal }) => {

  const [isListView, setIsListView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [loadDetail, setLoadDetail] = useState(false);
  const [archived, setArchived] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const columnHeader = ["Violation Reference #", "Violation Type", "Reported By", "Date Reported", "Assigned To", "Status"];

  const handleClickItem = async (item) => {
    setLoadDetail(true)
    try {
      const { data } = await useGetApiClient.get(`/admin/request/violation/get/${item.id}`);
      if (data?.error) throw new Error(data?.error);
      setDetails(data);
      setShowAddNew(true);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadDetail(false);
    }
  }

  const rows = (item) => {
    return (
      <>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{item.referenceNumber}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{item.type}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{item.reporter}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{item.createdAt}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{item.assignedTo}</div></td>
        <td>
          <div className="d-flex align-items-center cursor-pointer" style={{ height: '50px' }} onClick={() => handleClickItem(item)} >
            <div className={`${item.status?.toLowerCase() === "disapproves" ? 'bg-danger' : item.status?.toLowerCase() === "in-progress" ? 'bg-primary' : 'bg-success'} py-1 px-2 rounded-3 text-white`} style={{ fontSize: '12px', width: "fit-content" }}>{item.status}</div>
          </div>
        </td>
      </>
    )
  }

  useEffect(() => {
    const getList = async () => {
      setLoading(true);
      try {
        const { data } = await useGetApiClient.get(`/admin/request/violation/list?perPage=${perPage}&currentPage=${page}`);
        if (data?.error) throw new Error(data?.error);
        setList(data || [])
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    }

    getList();
  }, [isListView, archived, page, perPage]);

  return (
    <>
      {/* <div className="d-flex flex-row gap-2">
        <i className={`material-symbols-outlined me-1 md-18 cursor-pointer ${isListView && "text-primary"}`} onClick={() => setIsListView(true)}>format_list_numbered</i>
        <i className={`material-symbols-outlined me-1 md-18 cursor-pointer ${!isListView && "text-primary"}`} onClick={() => setIsListView(false)}>view_kanban</i>
      </div> */}
      {!showAddNew
        ? <>
          <Card className="config-tasks-list mt-3">
            <Card.Header>
              <div className="d-flex flex-row justify-content-between w-100">
                <div className="d-flex flex-row gap-4">
                  <h5
                    className={`${!archived ? 'text-decoration-underline fw-bold' : ''} text-primary`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setArchived(false)}
                  >
                    Violation
                  </h5>
                  <h5
                    className={`${archived ? 'text-decoration-underline fw-bold' : ''} text-primary ms-2`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setArchived(true)}
                  >
                    Archive
                  </h5>
                </div>
                {/* <Button
                  className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                  disabled={loading}
                // onClick={() => setShowAddNewArc(true)}
                >
                  <i className="material-symbols-outlined me-1 md-18">add</i>
                  Create Violation
                </Button> */}
              </div>
            </Card.Header>
            <Card.Body className="pt-0">
              <div className="table-responsive">
                <table className="config-tasks-list_table table">
                  <thead>
                    <tr>
                      {columnHeader.map((item, index) => (
                        <th key={index} scope="col" className="config-tasks-list_table--header text-primary">{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {(loading || loadDetail)
                      ? <tr><td colSpan={columnHeader.length}><SpinningBlueCircleLoader /></td></tr>
                      : (list?.violationReports?.length > 0)
                        ? list?.violationReports?.map((violation, idx) => (
                          <tr key={idx}>
                            {rows(violation)}
                          </tr>
                        ))
                        : <tr><td colSpan={columnHeader.length} className="text-center">No record found</td></tr>
                    }
                  </tbody>
                </table>
              </div>
              <div className="pt-4">
                <Pagination page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} totalCount={list?.count ?? 0} />
              </div>
            </Card.Body>
          </Card>
        </>
        : <ViolationStatus setShowAddNew={setShowAddNew} details={details} setDetails={setDetails} />
      }
    </>
  )
}

export default ViolationList;