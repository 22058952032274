import { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import { SpinningBlueCircleLoader, SpinningWhiteCircleLoader } from "../../../components/common/loaders";
import { useGetApiClient, useUploadPictureApi } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import Pagination from "../../../components/common/pagination";
import ConfirmModal from "../../../components/common/ConfirmModal";
import FileViewerComp from "../../../components/common/FileViewerComp";

const DocumentsList = ({ changesModal, setChangesModal }) => {
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [documentsList, setDocumentsList] = useState([{ title: null, name: null, file: null, type: null, size: null }]);
  const [archived, setArchived] = useState(false);
  const fileInputRefs = useRef([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openModal, setOpenModal] = useState({ save: false, delete: false, confirmDelete: false });
  const [deleteData, setDeleteData] = useState({ index: null, id: null });
  const [toggleView, setToggleView] = useState(false);
  const [fileView, setFileView] = useState(null);

  const handleSelectView = (file) => {
    const params = {
      ...file,
      fileType: file?.fileType,
      path: file.file.toLowerCase().includes("https://")
        ? file.file
        : file.original_file,
    };
    setFileView(params);
    setToggleView(true);
  };

  const handleFileChange = async (e, index) => {
    setFileLoading(true);
    const reader = new FileReader();
    const formData = new FormData();
    const file = e.target.files[0]
    formData.append('file', file);
    reader.readAsDataURL(file);
    let original_file = null;

    reader.onload = (e) => {
      original_file = e.target.result;
    };

    try {
      const { data } = await useUploadPictureApi.post('/admin/document/upload', formData);
      if (data?.error) throw new Error(data?.error);
      setDocumentsList(documentsList.map((item, i) => {
        if (i === index) return { title: item.title, name: file.name, file: data?.directoryPath, type: file.type, size: file.size, original_file: original_file, fileType: data?.directoryPath?.slice(data?.directoryPath.lastIndexOf(".") + 1, data?.directoryPath.length) }
        else return item
      }));
      setFileLoading(false);
      const inputfile = document.getElementsByName(`document[${index}]`)?.[0];
      inputfile.value = "";
    } catch (error) {
      toast.error(error?.message);
      setFileLoading(false);
    }
  }

  const handleDelete = async (index, id) => {
    try {
      setDeleteLoading(true);
      if (id) {
        const { data } = await useGetApiClient.delete(`/admin/document/delete/${id}`);
        if (data?.error) throw new Error(data?.error);
        setOpenModal({ ...openModal, confirmDelete: false, delete: true })
      }
      const filteredList = documentsList.filter((_, i) => i !== index);
      setDocumentsList(filteredList.length > 0 ? filteredList : [{ title: null, name: null, file: null, type: null, size: null }])
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
    } finally {
      setDeleteLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitLoading(true);
      const documents = documentsList.filter(item => !item.id)
      if (documents.length > 0) {
        const { data } = await useGetApiClient.post('/admin/document/save',
          documents
        );
        if (data?.error) throw new Error(data?.error);
      }
      setOpenModal({ ...openModal, save: true })
      getDocumentList();
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  const getDocumentList = async () => {
    setLoading(true);
    try {
      const { data } = await useGetApiClient.get(`/admin/document/list/${!archived ? 0 : 1}`);
      if (data?.error) throw new Error(data?.error);
      setDocumentsList(data.length > 0 ? data : [{ title: null, name: null, file: null, type: null, size: null }])
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDocumentList();
  }, [archived]);

  return (
    <>
      <Card className="config-tasks-list mt-3">
        <Card.Header>
          <div className="d-flex flex-row justify-content-between w-100">
            <div className="d-flex flex-row gap-4">
              <h5
                className={`${!archived ? 'text-decoration-underline fw-bold' : ''} text-primary`}
                style={{ cursor: 'pointer' }}
                onClick={() => setArchived(false)}
              >
                Documents
              </h5>
              <h5
                className={`${archived ? 'text-decoration-underline fw-bold' : ''} text-primary ms-2`}
                style={{ cursor: 'pointer' }}
                onClick={() => setArchived(true)}
              >
                Archive
              </h5>
            </div>
          </div>
        </Card.Header>
        <Card.Body className="pt-0">
          {loading
            ? <SpinningBlueCircleLoader />
            : <Form onSubmit={handleSubmit}>
              {documentsList.map((item, index) => (
                <Row className="form-group align-items-start" key={index}>
                  {(index === 0 && !archived)
                    ? <i className="material-symbols-outlined filled text-primary w-auto cursor-pointer" style={{ fontSize: "27px", marginTop: 40 }} onClick={() => setDocumentsList([...documentsList, { title: null, name: null, file: null, type: null, size: null }])}>add_box</i>
                    : <div style={{ width: "41px" }} />
                  }
                  <div className="col-sm-4 col-lg-5" style={{ height: 70 }}>
                    <label className="control-label mb-2" htmlFor={`document[${index}].title`}>Document Title</label>
                    <input type="text" value={item.title ?? ""} className="form-control" style={{ height: 50 }} id={`document[${index}].title`} name={`document[${index}].title`} placeholder="-" readOnly={archived || item.id} onChange={(e) => {
                      setDocumentsList(documentsList.map((item, i) => {
                        if (i === index) return { ...item, title: e.target.value }
                        else return item
                      }));
                    }} required />
                  </div>
                  <div className="col-sm-4 col-lg-5" style={{ height: 70 }}>
                    <label className="control-label mb-2">File Name</label>
                    <div className="form-control" style={{ minHeight: "50px" }}>
                      {item.name &&
                        <div className="d-flex flex-row align-items-center justify-content-between gap-1 rounded-3 bg-danger" style={{ width: "fit-content", color: "white", padding: "5px 8px", cursor: "pointer" }} onClick={() => handleSelectView(item)}>
                          <span>{item.name}</span>
                          {/* } */}
                        </div>
                      }
                    </div>
                    <input
                      type="file"
                      disabled={fileLoading}
                      ref={(el) => (fileInputRefs.current[index] = el)}
                      style={{ opacity: 0, pointerEvents: 'none', height: 0, width: 0 }}
                      name={`document[${index}]`}
                      onChange={(e) => handleFileChange(e, index)}
                    />
                  </div>
                  {!archived &&
                    <>
                      {!item.id &&
                        <>
                          {fileLoading ?
                            <div style={{ width: 42, marginTop: 40 }}>
                              <SpinningBlueCircleLoader />
                            </div> :
                            <i className="material-symbols-outlined text-primary w-auto cursor-pointer" style={{ marginTop: 40 }} onClick={() => fileInputRefs.current[index].click()}>upload</i>
                          }
                        </>
                      }
                      {documentsList.length > 1 &&
                        <i className="material-symbols-outlined text-danger w-auto cursor-pointer" style={{ marginTop: 40 }} onClick={() => {
                          item.id ? setOpenModal({ ...openModal, confirmDelete: true }) : handleDelete(index, item.id);
                          setDeleteData({ index: index, id: item.id });
                        }}>delete</i>
                      }
                    </>
                  }
                </Row>
              ))}
              {!archived &&
                <Form.Check className="form-group d-flex align-items-center justify-content-center gap-2">
                  <button type="submit" className="btn btn-primary w-100 mt-3">
                    {!submitLoading ? 'Save' : <SpinningWhiteCircleLoader />}
                  </button>
                </Form.Check>
              }
            </Form>
          }
        </Card.Body>
      </Card>

      <FileViewerComp toggleView={toggleView} setToggleView={setToggleView} fileView={fileView} />

      <ConfirmModal
        open={openModal.save}
        onHide={() => setOpenModal({ ...openModal, save: false })}
        titleIcon={<i className="material-symbols-outlined text-success" style={{ fontSize: "130px" }}>check_circle</i>}
        titleText={<h5 className="text-success">Save</h5>}
        confirmText="Successfully saved."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-success w-100" onClick={() => setOpenModal({ ...openModal, save: false })}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.delete}
        onHide={() => setOpenModal({ ...openModal, delete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Successfully deleted."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-danger w-100" onClick={() => {
            setOpenModal({ ...openModal, delete: false });
          }}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.confirmDelete}
        onHide={() => setOpenModal({ ...openModal, confirmDelete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Are you sure you want to delete?"
        isTwoActions={true}
        confirmButton={
          <button className="btn btn-danger w-50" onClick={deleteLoading ? () => { } : () => handleDelete(deleteData.index, deleteData.id)}>
            {!deleteLoading ? 'Yes' : <SpinningWhiteCircleLoader />}
          </button>
        }
        cancelButton={
          <button className="btn btn-primary w-50" onClick={() => setOpenModal({ ...openModal, confirmDelete: false })}>
            No
          </button>
        }
      />
    </>
  )
}

export default DocumentsList;