import React, { useState } from 'react';
import useGetUserListApi from '../../../../hooks/user-management/useGetUsersListApi';
import { Button, Card, Form } from 'react-bootstrap';
import Pagination from '../../../../components/common/pagination';
import { SpinningBlueCircleLoader } from '../../../../components/common/loaders';
import useGetUsersSubCommitteeListApi from '../../../../hooks/user-management/useGetUsersSubCommitteeListApi';
import AddNewUser from './addNewUser';
import { useGetApiClient } from '../../../../api/useApiClient';
import ConfirmModal from '../../../../components/common/ConfirmModal';

const UsersList = () => {
  const [usersPage, setUsersPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [subCommPage, setSubCommPage] = useState(0);
  const [subCommPerPage, setSubCommPerPage] = useState(10);
  const [showCreateUpdateModal, setShowCreateUpdateModal] = useState({ open: false, data: "" })
  const [refetch, setRefetch] = useState(false);
  const [refetchSub, setRefetchSub] = useState(false);
  const [openModal, setOpenModal] = useState({ save: false, delete: false, confirmDelete: false });

  const { loading: isUsersListLoading, usersList } = useGetUserListApi(usersPage, usersPerPage, refetch);
  const { loading: isSubCommListLoading, subCommitteeList } = useGetUsersSubCommitteeListApi(subCommPage, subCommPerPage, refetchSub);

  const handleChangeStatus = async (e, user) => {
    try {
      console.log(e);
      console.log(user);
      const { data } = await useGetApiClient.put(`/admin/user/update-status/${user.id}/${e.target.checked ? "ENABLED" : "DISABLED"}`);

      if (data?.error) throw new Error(data?.error);
      setOpenModal({ ...openModal, save: true })
      user?.sub ? setRefetchSub(!refetchSub) : setRefetch(!refetch);
    } catch (error) {

    }
  }

  const renderContentContainer = (content) => {
    return <div className="d-flex align-items-center" style={{ height: '50px' }}>{content}</div>
  }

  const renderText = (text) => {
    if (!text) return renderContentContainer("-");
    return renderContentContainer(text);
  }

  const renderStatus = (status) => {
    return (
      <div className="d-flex align-items-center" style={{ height: '50px' }}>
        <div
          className={`${status === "ENABLED" ? 'bg-success' : 'bg-danger'} py-1 px-2 rounded-3 text-white`}
          style={{ fontSize: '12px' }}
        >
          {status}
        </div>
      </div>
    )
  }

  const renderUsersListRows = (datas) => {
    return (
      datas?.map((user, idx) => (
        <tr key={idx}>
          <td
            name="Username"
          >
            {renderText(user?.username)}
          </td>
          <td
            name="Name"
          >
            {renderText(user?.firstName + ' ' + user?.lastName)}
          </td>
          <td
            name="Contact Number"
          >
            {renderText(user?.contactNumber)}
          </td>
          <td
            name="Email"
          >
            {renderText(user?.email)}
          </td>
          <td
            name="Role"
          >
            {renderText(user?.roles || user?.sub)}
          </td>
          <td
            name="Status"
          >
            {renderStatus(user?.status)}
          </td>
          <td
            name="Action"
            className="d-flex flex-row gap-2 align-items-center"
          >
            <div className="d-flex align-items-center" style={{ height: '50px' }}>
              <button
                type="button"
                className="bg-primary py-1 d-flex align-items-center border-0 text-white rounded-3"
                onClick={() => setShowCreateUpdateModal({ open: true, data: user })}
              >
                <i className="material-symbols-outlined">edit_square</i>
              </button>
            </div>

            <Form.Check
              className="d-flex align-items-center form-switch form-check-inline"
              style={{ height: '50px' }}
            >
              <Form.Check.Input
                type="checkbox"
                className="bg-primary"
                id="homeownerArchiveToggle"
                defaultChecked={user?.status === "ENABLED"}
                onChange={(e) => handleChangeStatus(e, user)}
              />
            </Form.Check>
          </td>
        </tr>
      ))
    )
  }

  const renderUsersList = (datas) => {
    if (datas?.length < 1) return <tr><td colSpan={8} className="text-center">No record found</td></tr>;
    return renderUsersListRows(datas);
  }

  const renderUsersListTable = (datas, isSub) => {
    if ((!isSub && isUsersListLoading) || (isSub && isSubCommListLoading)) return <SpinningBlueCircleLoader />;

    return (
      <table className="config-tasks-list_table table">
        <thead>
          <tr>
            <th scope="col" className="config-tasks-list_table--header text-primary">Username</th>
            <th scope="col" className="config-tasks-list_table--header text-primary">Name</th>
            <th scope="col" className="config-tasks-list_table--header text-primary">Contact Number</th>
            <th scope="col" className="config-tasks-list_table--header text-primary">Email</th>
            <th scope="col" className="config-tasks-list_table--header text-primary">{!datas?.find(({ sub }) => sub) ? "Role" : "Sub"}</th>
            <th scope="col" className="config-tasks-list_table--header text-primary">Status</th>
            <th scope="col" className="config-tasks-list_table--header text-primary">Action</th>
          </tr>
        </thead>
        <tbody>
          {renderUsersList(datas)}
        </tbody>
      </table>
    );
  }

  return (
    <>
      <Card className="config-tasks-list">
        <div className="config-tasks-list_header">
          <div className="d-flex flex-row justify-content-between w-100">
            <h5 className="text-primary">
              Users
            </h5>
            <span className="table-add float-end mb-3 me-2">
              <Button
                className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                onClick={() => setShowCreateUpdateModal({ open: true, data: "" })}
              >
                <i className="material-symbols-outlined me-1 md-18">add</i>
                Create User
              </Button>
            </span>
          </div>
        </div>
        <Card.Body className="pt-0">
          <div className="table-responsive">
            {renderUsersListTable(usersList?.users, false)}
          </div>
          <div className="pt-4">
            <Pagination page={usersPage} setPage={setUsersPage} perPage={usersPerPage} setPerPage={setUsersPerPage} totalCount={usersList?.count ?? 0} />
          </div>
        </Card.Body>

        <div className="config-tasks-list_header">
          <div className="d-flex flex-row justify-content-between w-100">
            <h5 className="text-primary">
              Sub-committee
            </h5>
          </div>
        </div>
        <Card.Body className="pt-0">
          <div className="table-responsive">
            {renderUsersListTable(subCommitteeList?.users, true)}
          </div>
          <div className="pt-4">
            <Pagination page={subCommPage} setPage={setSubCommPage} perPage={subCommPerPage} setPerPage={setSubCommPerPage} totalCount={subCommitteeList?.count ?? 0} />
          </div>
        </Card.Body>
      </Card>

      <AddNewUser
        open={showCreateUpdateModal.open}
        onHide={() => setShowCreateUpdateModal({ open: false, data: "" })}
        rolesList={usersList?.roles || []}
        userDetails={showCreateUpdateModal?.data}
        refetch={() => setRefetch(!refetch)}
      />

      <ConfirmModal
        open={openModal.save}
        onHide={() => setOpenModal({ ...openModal, save: false })}
        titleIcon={<i className="material-symbols-outlined text-success" style={{ fontSize: "130px" }}>check_circle</i>}
        titleText={<h5 className="text-success">Save</h5>}
        confirmText="User status successfully saved."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-success w-100" onClick={() => setOpenModal({ ...openModal, save: false })}>
            Okay
          </button>
        }
      />
    </>
  )
}

export default UsersList;