// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.config-tabs .profile-feed-items li::after {
  background: #fff !important;
}
.config-tabs .config-tabs_tab {
  width: 100%;
}
.config-tabs .config-tabs_tab:hover {
  cursor: pointer;
}
.config-tabs .config-tabs_tab:hover .config-tabs_tab--icon {
  background-color: #58BBFE !important;
  color: #ffff;
}
.config-tabs .config-tabs_tab:hover .config-tabs_tab--title {
  color: #58BBFE;
}
.config-tabs .config-tabs_tab .config-tabs_tab--card .config-tabs_tab--icon {
  width: 4rem;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/screens/system-configuration/config-tabs.scss"],"names":[],"mappings":"AAGG;EACC,2BAAA;AAFJ;AAOI;EACI,WAAA;AALR;AAOQ;EACI,eAAA;AALZ;AAOY;EACI,oCAAA;EACA,YAAA;AALhB;AAQY;EACI,cAAA;AANhB;AAYY;EACI,WAAA;EACA,cAAA;AAVhB","sourcesContent":[".config-tabs {\n    .profile-feed-items {\n\t\tli {\n\t\t\t&::after {\n\t\t\t\tbackground: #fff !important;\n\t\t\t}\n\t\t}\n\t}\n\n    .config-tabs_tab {\n        width: 100%;\n\n        &:hover {\n            cursor: pointer;\n\n            .config-tabs_tab--icon {\n                background-color: #58BBFE !important;\n                color: #ffff;\n            }\n\n            .config-tabs_tab--title {\n                color: #58BBFE;\n            }\n        }\n\n        .config-tabs_tab--card {\n\n            .config-tabs_tab--icon {\n                width: 4rem;\n                margin: 0 auto;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
