import { Button, Card, Col, Form, Row } from "react-bootstrap";
import useGetUserProfileApi from "../../hooks/profile/useGetUserProfileApi";
import { SpinningBlueCircleLoader, SpinningWhiteCircleLoader } from "../../components/common/loaders";
import useDashboardStore from "../../zustand/useDashboardStore";
import useGetRolesApi from "../../hooks/role/useGetRolesApi";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetApiClient, useUploadPictureApi } from "../../api/useApiClient";

import house from "../../assets/images/user/1.jpg";
import defaultUser from "../../assets/images/user/default_user-gray.png";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [rolesWithoutSub, setRolesWithoutSub] = useState([]);
  const [gender, setGender] = useState("MALE");
  const [attachedPicture, setAttachedPicture] = useState(defaultUser);


  const { loading: userInfoLoading, userInfo } = useGetUserProfileApi();
  const { loading: rolesLoading, rolesMenus } = useGetRolesApi();
  const { authUser, setAuthUser } = useDashboardStore();




  useEffect(() => {
    if (rolesMenus) {
      setRolesWithoutSub(rolesMenus?.roles?.filter((role) => role?.sub === null));
    }
  }, [setRolesWithoutSub, rolesMenus]);

  useEffect(() => {
    if (userInfo) {
      setGender(userInfo?.personal?.gender);
      setAttachedPicture(userInfo?.personal?.picture ?? defaultUser)
    }
  }, [userInfo, setGender, setAttachedPicture]);

  const handleAttachPicture = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    const inputfile = document.getElementsByName("userPicture")?.[0];
    inputfile.value = "";

    try {
      const { data: uploadedPicture } = await useUploadPictureApi.post('/admin/user/upload/picture', formData);
      if (uploadedPicture?.error) throw new Error(uploadedPicture?.error);
      setAttachedPicture(uploadedPicture?.directoryPath);
      const { data: updatedPicture } = await useGetApiClient.put(`admin/user/me/update`, {
        "picture": uploadedPicture?.directoryPath
      })
      toast.success(updatedPicture?.message);
      setAuthUser({ ...authUser, picture: uploadedPicture?.directoryPath })
      setLoading(false);
    } catch (error) {
      toast.error(error?.message);
      setLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await useGetApiClient.put(`admin/user/me/update/personal`, {
        "title": e.target.title.value, // nullable | string
        "firstName": e.target.firstName.value, // required | string
        "lastName": e.target.lastName.value, // required | string
        "gender": gender, // nullable | string, value can be MALE or FEMALE
        "birthDate": e.target.birthDate.value, // nullable | date
        // "password": "123Password", // nullable | string
        "picture": attachedPicture || null // nullable | string
      })
      if (data?.error) throw new Error(data?.error);
      toast.success(data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  if (userInfoLoading) return <SpinningBlueCircleLoader />;
  return (
    <Form onSubmit={handleSubmit} style={{ marginBottom: '5rem' }}>
      <div className="bg-light mt-4 rounded-3 position-relative" style={{ height: '300px' }}>
        <div
          className="position-absolute rounded-circle bg-light mx-auto"
          style={{
            height: '170px',
            width: '170px',
            bottom: '-4.5rem',
            left: '25%',
            right: '25%',
          }}
        >
          <div
            className="position-relative rounded-circle bg-light mx-auto mt-2"
            style={{
              height: '150px',
              width: '150px',
              backgroundImage: `url(${attachedPicture || house})`,
              backgroundSize: 'cover',
              backgroundRepeat: "no-repeat",
            }}
          >
          </div>
          {/* <svg 
                        style={{
                            position: 'absolute',
                            right: '15px',
                            bottom: '0'
                        }}
                        width="40" 
                        height="40" 
                        viewBox="0 0 40 40" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="40" height="40" rx="20" fill="#047AD3"/>
                        <path d="M17.5 26.8749H13.75C13.5842 26.8749 13.4253 26.8091 13.3081 26.6918C13.1908 26.5746 13.125 26.4157 13.125 26.2499V22.7588C13.125 22.6767 13.1412 22.5954 13.1726 22.5196C13.204 22.4438 13.25 22.3749 13.3081 22.3168L22.6831 12.9418C22.8003 12.8246 22.9592 12.7588 23.125 12.7588C23.2908 12.7588 23.4497 12.8246 23.5669 12.9418L27.0581 16.433C27.1753 16.5502 27.2411 16.7091 27.2411 16.8749C27.2411 17.0407 27.1753 17.1996 27.0581 17.3168L17.5 26.8749Z" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M20.625 15L25 19.375" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> */}
          <h5 className="text-center pt-4">Admin user</h5>
        </div>
      </div>

      <Card className="py-4" style={{ marginTop: '8rem' }}>
        <div className="px-4 py-2">
          <div className="d-flex flex-row gap-2 align-items-center w-100">
            <h5 className="text-primary">Account number: {userInfo?.accountNumber}</h5>
            {/* <button
                            type="button"
                            className="bg-primary p-1 d-flex align-items-center border-0 text-white rounded-3"
                            style={{cursor: 'pointer'}}
                        >
                            <i className="material-symbols-outlined">edit</i>
                        </button> */}
          </div>
        </div>
      </Card>

      <Card className="py-4" style={{ marginTop: '2rem' }}>
        <div className="px-4 py-2">
          <div className="d-flex flex-row gap-2 align-items-center w-100">
            <h5 className="text-primary">Profile</h5>
          </div>
        </div>
        <Card.Body>
          <Row>
            <Col lg={10}>
              <Row className="form-group align-items-center">
                <label className="control-label col-sm-2 align-self-center mb-0">Title</label>
                <div className="col-sm-3">
                  <select
                    className="form-select"
                    id="title"
                    name='title'
                    defaultValue={userInfo?.personal?.title}
                  >
                    <option defaultValue="-">-</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                  </select>
                </div>
              </Row>
              <Row className="form-group align-items-center">
                <label className="control-label col-sm-2 align-self-center mb-0">Full name</label>
                <div className="col-sm-4">
                  <input type="text" className="form-control" id="firstName" name="firstName" defaultValue={userInfo?.personal?.firstName} placeholder="First name.." required />
                </div>
                <div className='col-sm-4 flex flex-row gap-2 align-items-center'>
                  <input type="text" className="form-control" id="lastName" name="lastName" defaultValue={userInfo?.personal?.lastName} placeholder="Last name.." required />
                </div>
              </Row>
              {/* <Row className="form-group align-items-center">
                                <label  className="control-label col-sm-2 align-self-center mb-0">Phone</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control" id="ownerFirstName" name="ownerFirstName" defaultValue={userInfo?.contact?.contactNumber} placeholder="-" />
                                </div>
                            </Row> */}
              <Row className="form-group align-items-center">
                <label className="control-label col-sm-2 align-self-center mb-0">Gender</label>
                <div className="col-sm-4 d-flex gap-2">
                  <Form.Check className="d-block mb-0">
                    <Form.Check.Input
                      type="radio"
                      name="genderMale"
                      id="genderMale"
                      defaultChecked={gender === "MALE"}
                      checked={gender === "MALE"}
                      onChange={() => setGender("MALE")}
                    />{" "}
                    <Form.Check.Label>
                      Male
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="d-block mb-0">
                    <Form.Check.Input
                      type="radio"
                      name="genderFemale"
                      id="genderFemale"
                      defaultChecked={gender === "FEMALE"}
                      checked={gender === "FEMALE"}
                      onChange={() => setGender("FEMALE")}
                    />{" "}
                    <Form.Check.Label>
                      Female
                    </Form.Check.Label>
                  </Form.Check>
                </div>
              </Row>
              <Row className="form-group align-items-center">
                <label className="control-label col-sm-2 align-self-center mb-0">Birthday</label>
                <div className="col-sm-4">
                  <input type="date" className="form-control" id="birthDate" defaultValue={userInfo?.personal?.birthDate} name="birthDate" placeholder="-" required />
                </div>
              </Row>
              <Row>
                <div className="d-flex flex-row gap-4 col-sm-10">
                  {rolesLoading ? <SpinningBlueCircleLoader /> : (
                    rolesWithoutSub?.map((role, idx) => (
                      <div key={idx} className="form-check col-sm px-5 py-3 border border-light d-flex justify-content-center align-items-center rounded-3">
                        <input
                          className="border border-primary"
                          type="checkbox"
                          name="role"
                          // defaultChecked={homeownerDetails?.roles?.includes(role?.id)}
                          value={role?.id}
                          // onChange={(e) => handleOnRolesChecked(e)}
                          id="role"
                        />
                        <label className="form-check-label ms-2 d-flex flex-column gap-2 align-items-center" htmlFor="role">
                          <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_2342_8352)">
                              <path d="M6.20898 7.5C6.20898 9.48912 7.05184 11.3968 8.55213 12.8033C10.0524 14.2098 12.0873 15 14.209 15C16.3307 15 18.3655 14.2098 19.8658 12.8033C21.3661 11.3968 22.209 9.48912 22.209 7.5C22.209 5.51088 21.3661 3.60322 19.8658 2.1967C18.3655 0.790176 16.3307 0 14.209 0C12.0873 0 10.0524 0.790176 8.55213 2.1967C7.05184 3.60322 6.20898 5.51088 6.20898 7.5ZM12.1152 19.2305L13.2777 21.0469L11.1965 28.3066L8.94648 19.6992C8.82148 19.2246 8.33398 18.9141 7.82773 19.0371C3.45273 20.0625 0.208984 23.7773 0.208984 28.2012C0.208984 29.1973 1.07148 30 2.12773 30H10.359C10.359 30 10.359 30 10.3652 30H10.709H17.709H18.0527C18.0527 30 18.0527 30 18.059 30H26.2902C27.3527 30 28.209 29.1914 28.209 28.2012C28.209 23.7773 24.9652 20.0625 20.5902 19.0371C20.084 18.9199 19.5965 19.2305 19.4715 19.6992L17.2215 28.3066L15.1402 21.0469L16.3027 19.2305C16.7027 18.6035 16.2215 17.8125 15.4465 17.8125H14.209H12.9777C12.2027 17.8125 11.7215 18.6094 12.1215 19.2305H12.1152Z" fill="#6C757D" />
                            </g>
                            <defs>
                              <clipPath id="clip0_2342_8352">
                                <rect width="28" height="30" fill="white" transform="translate(0.208984)" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>{role?.name}</span>
                        </label>
                      </div>
                    ))
                  )}
                </div>
              </Row>
            </Col>
            <Col lg={2} className="my-auto">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div>
                  <img
                    src={attachedPicture}
                    className="img-fluid"
                    alt="user"
                    loading="lazy"
                  // style={attachedPicture && {height: '200px'}}
                  />
                </div>
                <div className="d-flex flex-row gap-3 mt-4">
                  <div className="d-flex align-items-center">
                    <label htmlFor="userPicture" className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                      <i className="material-symbols-outlined">attach_file</i>
                    </label>
                    <input
                      id="userPicture"
                      name="userPicture"
                      type="file"
                      accept="image/*"
                      className="d-none"
                      onChange={(e) => handleAttachPicture(e)}
                    />
                  </div>
                  <button
                    type="button"
                    className="border-0 bg-transparent d-flex align-items-center text-danger"
                    onClick={() => setAttachedPicture(defaultUser)}
                  >
                    <i className="material-symbols-outlined">delete</i>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Form.Check className="form-group d-flex align-items-center justify-content-center">
        <button type="submit" className="btn btn-primary w-50 ">
          {loading ? <SpinningWhiteCircleLoader /> : 'Save'}
        </button>
      </Form.Check>
    </Form>
  )
}

export default Profile;